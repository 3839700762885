/* Estilos para el componente AccesoriosEquipo */

/* Título del diálogo */
.container-title-recomendaciones {
    text-align: center;
    padding: 5px 0;
  }
  
  .title-recomendaciones {
    color: #03178C;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }
  
  /* Estilos para los iconos */
  .icon-crearcliente {
    font-size: 1.4rem;
  }
  
  .icon-formulario {
    font-size: 1.2rem;
  }
  
  .icon-seccion {
    font-size: 1.3rem;
    margin-right: 10px;
    color: #03178C;
  }
  
  /* Cabecera de sección */
  .seccion-cabecera {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .seccion-titulo {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: #333 !important;
    margin: 0 !important;
  }
  
  /* Contenedor principal de accesorios */
  .container-recomendaciones {
    padding: 10px 5px;
  }
  
  /* Contenedor de listado de accesorios */
  .accesorios-container {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
  }
  
  /* Indicador general de accesorios */
  .accesorios-indicator {
    margin-bottom: 15px;
  }
  
  /* Cada accesorio individual */
  .accesorio-item {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  /* Título del accesorio */
  .accesorio-titulo {
    color: #03178C;
    margin-bottom: 8px;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  /* Contenedor de detalles del accesorio */
  .accesorio-details {
    display: flex;
    flex-wrap: wrap;
  }
  
  .accesorio-detail-item {
    margin-right: 20px;
    margin-bottom: 5px;
  }
  
  /* Mensaje cuando no hay accesorios */
  .no-accesorios {
    text-align: center;
    padding: 30px 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
  }
  
  .no-accesorios-titulo {
    color: #03178C !important;
    margin-bottom: 10px !important;
  }
  
  .no-accesorios-mensaje {
    color: #505050 !important;
  }
  
  .no-accesorios-sugerencia {
    margin-top: 15px !important;
    color: #707070 !important;
  }
  
  /* Spinner de carga */
  .login-progress-recomendation-uso {
    position: absolute !important;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
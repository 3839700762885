/* Estilos para el componente DocumentoSubir */

.documento-subir-dropzone-container {
    margin-top: 20px;
    width: 100%;
  }
  
  .documento-subir-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 8px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
  }
  
  .documento-subir-dropzone:hover {
    border-color: #03178C;
  }
  
  .documento-subir-text {
    margin-bottom: 5px;
    font-size: 16px;
    text-align: center;
  }
  
  .documento-subir-info {
    font-size: 14px;
    color: #777;
  }
  
  .documento-subir-preview {
    margin-top: 16px;
  }
  
  .documento-subir-file-error {
    color: #f44336;
    margin-bottom: 16px;
  }
  
  .documento-subir-file-accepted {
    color: #4caf50;
    margin-bottom: 16px;
  }
  
  .documento-subir-file-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .documento-subir-file-item {
    padding: 8px 0;
  }
  
  /* Mensaje de aprobado */
  .documento-subir-aprobado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .documento-subir-icono-container {
    background-color: #20B90C;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .documento-subir-icono {
    font-size: 40px;
  }
  
  .documento-subir-texto {
    color: #20B90C;
    text-align: center;
    font-size: 24px;
  }
  
  /* Errores */
  .documento-subir-error-container {
    margin-top: 16px;
  }
  
  .documento-subir-error-message {
    color: #f44336;
    margin: 8px 0;
  }
  
  /* Botones e iconos */
  .documento-subir-form-icon {
    font-size: 24px;
  }
  
  .documento-subir-btn-icon {
    font-size: 24px;
  }
  
  .documento-subir-progress {
    position: absolute;
  }
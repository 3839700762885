/* Sección principal */
#section-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #e4e4e4 0%, #f6f6f6 100%);
    padding: 40px 20px;
    min-height: 840px;
  }
  
  .content-container, .image-container {
    flex: 1 1 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .content-container {
    padding-left: 40px;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .content-container.visible {
    opacity: 1;
    transform: translateX(0);
  }
  
  .image-plan {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .image-plan.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Animaciones de textos */
  .header-primary, .header-secondary, .header-tertiary {
    color: #0455BF;
    margin: 0;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .content-container.visible .header-primary,
  .content-container.visible .header-secondary,
  .content-container.visible .header-tertiary {
    opacity: 1;
    transform: translateX(0);
  }
  
  .header-primary {
    font-size: 72px;
    font-weight: 700;
    transition-delay: 0.1s;
  }
  
  .header-secondary {
    font-size: 40px;
    font-weight: 500;
    transition-delay: 0.2s;
  }
  
  .header-tertiary {
    font-size: 26px;
    font-weight: 400;
    transition: all 0.3s ease;
    text-decoration: none;
    color: #0455BF;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  
  .header-tertiary::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }
  
  .header-tertiary:hover {
    color: #0455BF; /* Un tono de azul que podrías ajustar según tu paleta de colores */
  }
  
  .header-tertiary:hover::after {
    transform: scaleX(1);
  }
  
  @media (min-width: 768px) {
    .content-container, .image-container {
      flex: 1 1 50%;
    }
  }
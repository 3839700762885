/* Estilos para RecomendacionesDescripcionGemini.css */

.container-title-descripcion {
    text-align: center;
  }
  
  .title-descripcion {
    color: #03178C;
    margin-bottom: 15px;
  }
  
  .container-descripcion {
    width: 100%;
  }
  
  .seccion-cabecera-descripcion {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .icon-seccion-descripcion {
    margin-right: 10px;
    font-size: 18px;
    color: #03178C;
  }
  
  .seccion-titulo-descripcion {
    font-weight: 600 !important;
    color: #03178C !important;
  }
  
  .descripcion-existente, .descripcion-ia {
    margin-bottom: 20px;
  }
  
  .descripcion-item {
    margin-bottom: 15px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border-left: 3px solid #03178C;
    transition: all 0.3s ease;
  }
  
  .descripcion-item:hover {
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    transform: translateY(-2px);
  }
  
  .descripcion-text {
    color: #333;
    line-height: 1.6;
    margin: 0;
    text-align: justify;
    font-size: 15px;
  }
  
  .no-descripcion {
    text-align: center;
    padding: 20px;
    color: #666;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .login-progress-descripcion {
    position: absolute !important;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
#listado-precios {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 60px auto;
   
  }
  
  #listado-precios h1 {
    text-align: center;
    color: #283374;
    margin-bottom: 40px;
    font-size: 3em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
  }
  
  #listado-precios h1::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #3182ce, #63b3ed);
    margin: 20px auto;
    border-radius: 2px;
  }
  
  .planes-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 30px;
    padding: 20px;
    perspective: 1000px;
  }
  
  .plan-card {
    background: white;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
  }
  
  .plan-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, #3182ce, #63b3ed);
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .plan-card:hover {
    transform: translateY(-10px);
    border-color: #3182ce;
  }
  
  .plan-card:hover::before {
    transform: scaleX(1);
  }
  
  .plan-card.destacado {
    background: linear-gradient(135deg, #ebf8ff 0%, #ffffff 100%);
    border-color: #3182ce;
  }
  
  .plan-card h2 {
    color: #2c5282;
    margin-bottom: 20px;
    font-size: 1.8em;
    text-align: center;
    font-weight: bold;
  }
  
  .badge {
    position: absolute;
    top: 20px;
    right: -30px;
    background: #3182ce;
    color: white;
    padding: 5px 30px;
    transform: rotate(45deg);
    font-size: 0.8em;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .precio {
    text-align: center;
    margin: 30px 0;
    padding: 20px 0;
    border-bottom: 2px solid #edf2f7;
    position: relative;
  }
  
  .precio .moneda {
    color: #4a5568;
    font-size: 1.2em;
    font-weight: 500;
  }
  
  .precio .cantidad {
    font-size: 3em;
    font-weight: bold;
    color: #2c5282;
    margin: 0 5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .caracteristicas-precios {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .caracteristicas-precios li {
    margin: 15px 0;
    display: flex;
    align-items: center;
    line-height: 1.5;
    color: #4a5568;
    transition: transform 0.2s ease;
  }
  
  .caracteristicas-precios li:hover {
    transform: translateX(5px);
  }
  
  .check-icon {
    color: #48bb78;
    margin-right: 12px;
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .notas {
    margin-top: 20px;
    padding: 15px;
    background: #f7fafc;
    border-radius: 10px;
    font-size: 0.9em;
    color: #9e9e9e;
    border: 1px solid #bababa;
  }
  
  .botones-accion {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
  
  .boton-primario, .boton-whatsapp {
    padding: 12px 24px;
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .boton-primario {
    background: #046DC7;
    color: white;
    border: none;
  }
  
  .boton-primario:hover {
    background: #0459a4;
    transform: translateY(-2px);
  }
  
  .boton-whatsapp {
    background: #25D366;
    color: white;
  }
  
  .boton-whatsapp:hover {
    background: #128C7E;
    transform: translateY(-2px);
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  /* Animación de entrada */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .plan-card {
    animation: fadeInUp 0.5s ease-out forwards;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .planes-container {
      grid-template-columns: 1fr;
    }
    
    #listado-precios {
      padding: 20px 10px;
    }
    
    .plan-card {
      padding: 20px;
    }
    
    .precio .cantidad {
      font-size: 2.5em;
    }
  }
/* Estilos para el componente DocumentoEliminar */

.documento-eliminar-btn-container {
    display: inline-block;
    margin-right: 10px;
  }
  
  .documento-eliminar-icon {
    font-size: 24px;
  }
  
  .documento-eliminar-form-icon {
    font-size: 24px;
  }
  
  /* Mensaje de aprobado */
  .documento-eliminar-aprobado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px 0;
  }
  
  .documento-eliminar-icono-container {
    background-color: #f9044e;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .documento-eliminar-icono {
    font-size: 40px;
  }
  
  .documento-eliminar-texto {
    color: #f9044e;
    text-align: center;
    font-size: 24px;
    margin-top: 10px;
  }
  
  /* Errores */
  .documento-eliminar-error-container {
    padding: 0 24px;
    margin-bottom: 20px;
  }
  
  .documento-eliminar-error-message {
    color: #f44336;
    margin: 8px 0;
  }
  
  /* Loading */
  .documento-eliminar-progress {
    position: absolute;
  }
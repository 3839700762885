/* IndicadoresPrestadorYear.css */
.year-dialog-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
}

.year-dialog-button {
    padding: 15px !important;
}

.dashing-card-indp {
    cursor: pointer;
    transition: transform 0.2s ease;
}

.dashing-card-indp:hover {
    transform: translateY(-2px);
}

.year-selector-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dashing-value-card-indp {
    margin: 0;
    display: flex;
    align-items: center;
}
.title-recomendaciones {
    color: #0070d1;
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 25px;
    text-align: start;
    margin-bottom: 20px;
    padding: 5px;
    text-shadow: #0013243b 1px 1px 20px;
  }
  
  .login-progress-recomendation {
    align-self: center;
    padding: 30px;
  }
  .container-title-recomendaciones{
    margin-bottom: 20px;
  }
  .container-recomendaciones {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
  }
  
  .recomendacion-detalle {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    color: #0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
  }
  
  .recomendacion-text {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color: #001324;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
    padding-bottom: 3px;
  }

  
.seccion-cabecera {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.icon-seccion {
  margin-right: 10px;
  font-size: 18px;
  color: #03178C;
}

.seccion-titulo {
  font-weight: 600 !important;
  color: #03178C !important;
}

.rutina-item, .recomendacion-item {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border-left: 3px solid #03178C;
}

.rutinas-existentes, .recomendaciones-ia {
  margin-bottom: 20px;
}

.no-recomendaciones {
  text-align: center;
  padding: 20px;
  color: #666;
}
.quienes-somos {
    background-color: #ffffff;
    padding: 80px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .header-titulo-q{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 600;
    color: #03178C;
    margin-block-start: 0;

  }
 
  
  .descripcion {
    font-size: 1.125rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .equipo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    margin-top: 40px;
  }
  
  .miembro-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    animation: fadeInUp 0.6s ease-out forwards;
    opacity: 0;
  }
  
  .miembro-card:nth-child(1) { animation-delay: 0.2s; }
  .miembro-card:nth-child(2) { animation-delay: 0.4s; }
  .miembro-card:nth-child(3) { animation-delay: 0.6s; }
  
  .imagen-container {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
  
  .imagen-perfil {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
    border: 2px solid transparent;
    filter: grayscale(100%);
  }
  
  .imagen-perfil:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-color: #0066cc;
    filter: grayscale(0%);
  }
  
  .nombre {
    font-size: 1.25rem;
    color: #03178C;
    margin: 10px 0;
    font-weight: 600;
    position: relative;
    display: inline-block;
  }
  
  .nombre::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #0066cc;
    transition: width 0.3s ease;
  }
  
  .nombre:hover::after {
    width: 100%;
  }
  
  .cargo {
    color: #0066cc;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .bio {
    color: #666;
    font-size: 0.875rem;
    line-height: 1.6;
    margin-bottom: 20px;
    padding: 0 10px;
  }
  
  .linkedin-link {
    display: inline-flex;
    align-items: center;
    background-color: #0077b5;
    color: white;
    text-decoration: none;
    font-size: 0.875rem;
    padding: 8px 16px;
    border-radius: 20px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .linkedin-link:hover {
    transform: translateY(-2px);
    background-color: #005885;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .linkedin-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .quienes-somos {
      padding: 80px 0;
    }
  
    .header h2 {
      font-size: 2rem;
    }
  
    .descripcion {
      font-size: 1rem;
    }
  
    .equipo-grid {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  
    .imagen-container {
      width: 180px;
      height: 180px;
    }
  }
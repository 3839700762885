/* Estilos para RecomendacionesUsoGemini.css */

.title-recomendaciones-uso {
    color: #03178C;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .container-recomendaciones-uso {
    width: 100%;
  }
  
  .seccion-cabecera-uso {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .icon-seccion-uso {
    margin-right: 10px;
    font-size: 18px;
    color: #03178C;
  }
  
  .seccion-titulo-uso {
    font-weight: 400 !important;
    color: #03178C !important;
  }
  
  .recomendaciones-existentes-uso, .recomendaciones-ia-uso {
    margin-bottom: 20px;
  }
  
  .recomendacion-item-uso {
    margin-bottom: 15px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border-left: 3px solid #03178C;
    transition: all 0.3s ease;
  }
  
  .recomendacion-item-uso:hover {
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    transform: translateY(-2px);
  }
  
  .text-titulo-cliente-uso {
    color: #03178C;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .recomendacion-text-uso {
    color: #333;
    line-height: 1.5;
    margin-top: 5px;
  }
  
  .no-recomendaciones-uso {
    text-align: center;
    padding: 20px;
    color: #666;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .login-progress-recomendation-uso {
    position: absolute !important;
    top: 50%;
    left: 50%;
    margin-top: 12px;
    margin-left: 12px;
  }
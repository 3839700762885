#solicitar-info {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    margin-top: 80px;
}

.info-container {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
    display: flex;
    gap: 2rem;
}

.info-container1 {
    flex: 1;
}

.info-container2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-card {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.info-divtitulo {
    
    text-align: center;
    margin-bottom: 2rem;
}

.info-titulo {
    font-size: 2rem;
    color: #03178C;
    margin-bottom: 0.5rem;
}

.info-subtitulo {
    color: #666;
    margin-bottom: 1rem;
}

.info-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.info-input {
    margin-bottom: 1rem;
}

.info-success {
    color: #4CAF50;
    text-align: center;
    margin: 0.5rem 0;
    font-weight: 500;
}

.info-error {
    color: #f44336;
    text-align: center;
    margin: 0.5rem 0;
}

.info-progress {
    margin: 1rem auto;
    display: block;
}

.info-mensaje {
    padding: 1rem;
    color: #666;
}

.info-mensaje p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.info-mensaje ul {
    margin: 1.5rem 0;
    padding-left: 2rem;
}

.info-mensaje li {
    margin-bottom: 0.5rem;
    line-height: 1.4;
}

.info-imagen {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 1.5rem auto;
    display: block;
    border-radius: 8px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .info-container {
        flex-direction: column;
    }
    
    .info-container1,
    .info-container2 {
        width: 100%;
    }
}
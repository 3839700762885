.div-tablacorr{
    padding: 10px;
}



  /* Renombrar .div-mediciones-container a .repuestos-fila */
.repuestos-fila {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

/* Renombrar .formcontrol-nuevomto a .repuesto-selector */
.repuesto-selector {
    width: 100%;
    min-width: 120px;
}

/* Renombrar .login-input-email a .repuesto-campo */
.repuesto-campo {
    display: block;
    width: 90%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #7f7f7f;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: none;
    transition: none;
}

/* Añadir nueva clase para columnas con ancho fijo */
.repuesto-columna {
    flex: 1;
    min-width: 120px;
    padding: 0 8px;
}

/* Clase específica para la columna del nombre del repuesto */
.repuesto-columna-nombre {
    flex: 2;
    min-width: 120px;
    padding: 0 8px;
}

.firma-canvas-container {
    width: 100%;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    background-color: white;
}

.firma-canvas {
    width: 100%;
    height: 200px;
    border: none;
    display: block;
}

.div-firma-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.btn-limpiar-firma, .btn-guardar-firma {
    flex: 1;
    margin: 0 5px;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
}

.btn-limpiar-firma {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
}

.btn-guardar-firma {
    background-color: #03178C;
    color: white;
    border: none;
}

.mt-2 {
    margin-top: 10px;
}

.firma-guardada-indicador {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #20B90C;
    margin: 10px 0;
}
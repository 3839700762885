.info-imagen-superior {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
    margin-bottom: 1.5rem;
}

.info-seccion-destacada {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.info-seccion-destacada h3 {
    color: #2c5282;
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.info-ventajas {
    margin: 2rem 0;
}

.info-ventajas h3 {
    color: #2c5282;
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.info-ventajas ul li {
    margin-bottom: 1rem;
    line-height: 1.6;
}

.info-cta {
    text-align: center;
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: #e6f7ff;
    border-radius: 8px;
}

.info-cta p {
    font-size: 1.1rem;
    color: #2c5282;
    margin-bottom: 1.5rem;
    font-weight: 500;
}

.info-logo {
    max-width: 200px;
    height: auto;
    margin: 0 auto;
}

.info-mensaje ul {
    list-style: none; /* Elimina los bullets por defecto */
    padding: 0; /* Elimina el padding por defecto */
}

.info-mensaje ul li {
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    position: relative;
}

.info-mensaje ul li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #4CAF50;
}
.listamtoprog-table{
    width: 95%;
    position: relative;
    margin-left: 5px;
}

.div-input-doble-mtoprog{
    display: flex;
    flex: 45%;
    flex-direction: row;
    margin-top: 5px;
    
    
}

.formcontrol-nuevomto{
width: 100%;
    
}
.div-mediciones-container{
    display: flex;
    flex-direction: row;
    flex: content;
}
.listamtoprog-container{
    width: 95%;
    margin-left: 10px;
}

.div-aprobado{
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.div-icono-aprobado{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #20B90C;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.div-icono-delete{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f9044e;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.div-icono-advertencia{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #ffd631;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}
.icono-aprobado{
    height: 100px;
    width: 100px;
}
.text-aprobado{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #a3a3a3;
}

/* Signature canvas styles */
.div-firma-digital {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  }
  
  .firma-canvas-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
    background-color: #fff;
  }
  
  .firma-canvas {
    width: 100%;
    height: 200px;
    touch-action: none;
  }
  
  .div-firma-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .firma-error {
    color: #f44336;
    margin: 8px 0;
    font-size: 0.75rem;
  }
  
  .firma-guardada {
    color: #4caf50;
    margin: 8px 0;
    font-weight: bold;
    text-align: center;
  }
  
  .error-message {
    margin: 0;
  }
  
  .btn-limpiar-firma {
    margin-right: 8px;
  }
  
  /* User selection styles */
  .div-seleccion-usuario {
    width: 100%;
    margin-top: 16px;
  }
  
  .div-usuario-info {
    margin-top: 16px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #f5f5f5;
  }
  
  .usuario-info-nombre,
  .usuario-info-cargo {
    margin: 4px 0;
  }
  
  /* Image upload styles */
  .div-carga-imagen {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  }
  
  .btn-upload-image {
    margin-bottom: 16px;
  }
  
  .div-preview-imagen {
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .preview-imagen-firma {
    max-width: 100%;
    max-height: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .mt-2 {
    margin-top: 16px;
  }

  .btn-limpiar-firma {
    background-color: white !important;
    color: #0b6cc1 !important;
    border: 1px solid #a9a9a9 !important;
    margin: 0 8px !important;
    font-weight: normal !important;
  }
  
  .btn-limpiar-firma:hover {
    background-color: #f5f5f5 !important;
  }
  
  .btn-guardar-firma {
    background-color: #021370 !important;
    color: white !important;
    margin: 0 8px !important;
    font-weight: normal !important;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2) !important;
  }
  
  .btn-guardar-firma:hover {
    background-color: #0b6cc1 !important;
  }
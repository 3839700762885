/* Estilos para el componente DocumentoEditar */

.documento-editar-btn-container {
    display: inline-block;
    margin-right: 10px;
  }
  
  .documento-editar-icon {
    font-size: 24px;
  }
  
  .documento-editar-form-icon {
    font-size: 24px;
  }
  
  /* Formulario */
  .documento-editar-formcontrol {
    width: 100%;
    margin-bottom: 20px !important;
  }
  
  .documento-editar-textfield {
    margin-bottom: 20px !important;
  }
  
  /* Mensaje de aprobado */
  .documento-editar-aprobado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px 0;
  }
  
  .documento-editar-icono-container {
    background-color: #20B90C;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .documento-editar-icono {
    font-size: 40px;
  }
  
  .documento-editar-texto {
    color: #20B90C;
    text-align: center;
    font-size: 24px;
    margin-top: 10px;
  }
  
  /* Errores */
  .documento-editar-error-container {
    margin-top: 16px;
  }
  
  .documento-editar-error-message {
    color: #f44336;
    margin: 8px 0;
  }
  
  /* Loading */
  .documento-editar-progress {
    position: absolute;
  }